<template>
  <div class="container content-themes">
    <template v-for="(item, i) in myDeviceInfo">
      <div class="page-sub-box clearfix per33 ui-33" style="padding-bottom: 20px" :key="i">
        <table class="table_form line-bin fl" style="width: 40%">
          <colgroup>
            <col style="width: 20%" />
            <col style="width: 30%" />
            <col style="width: 20%" />
            <col style="width: 30%" />
          </colgroup>
          <thead class="sub_title_txt">
            <tr>
              <td colspan="4">
                <h2 style="width: 200px" class="fl">{{ $_msgContents('UC.WORD.DEVICE_INFO') }}</h2>
                <DxButton
                  v-if="i == 0"
                  @click="selectData()"
                  :text="$_msgContents('UC.WORD.CHECK')"
                  :width="80"
                  :height="26"
                  class="dx-widget dx-button dx-button-mode-text dx-button-normal dx-button-has-text btn_XS default filled fr"
                  styling-mode="contained"
                  type="default"
                />
              </td>
            </tr>
          </thead>
          <!-- style="height: 375px" -->
          <tbody style="height: 250px">
            <tr>
              <th scope="row" class="alT">
                <label for="label01">{{ $_msgContents('UC.WORD.EXCHANGER') }}</label>
              </th>
              <th>{{ getCmName(myDeviceInfo[i].cmname) }}</th>
              <td colspan="2" rowspan="3" style="padding: 0px">
                <div class="fl" style="width: 100%; text-align: center">
                  <img v-if="deviceImage[i].src" :src="deviceImage[i].src" alt="Device" />
                  <img
                    v-if="deviceExpImage[i].src"
                    :src="deviceExpImage[i].src"
                    style="cursor: pointer; position: absolute; bottom: 0px"
                    alt="DeviceExp"
                    class="fr"
                    @click="isOpenModal(true, i)"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <th scope="row">
                <label for="label02">{{ $_msgContents('UC.WORD.DEVICE_NAME') }}</label>
              </th>
              <th>{{ myDeviceInfo[i].name }}</th>
            </tr>

            <tr>
              <th scope="row" class="alT"><label for="label04">IP</label></th>
              <th>{{ myDeviceInfo[i].ipaddress }}</th>
            </tr>
            <tr>
              <th scope="row" class="alT">
                <label for="label04">{{ $_msgContents('UC.WORD.MODEL') }}</label>
              </th>
              <th colspan="3">{{ myDeviceInfo[i].model }}</th>
            </tr>
            <tr>
              <th scope="row" class="alT">
                <label for="label04">{{ $_msgContents('COMPONENTS.DESCRIPTION') }}</label>
              </th>
              <td colspan="3">
                <DxTextBox
                  v-model="myDeviceInfo[i].description"
                  :placeholder="$_msgContents('UC.MESSAGE.DESCRIPTION_ENTER')"
                  :styling-mode="config.stylingMode"
                  @value-changed="onChangeInput($event, i)"
                />
              </td>
            </tr>
          </tbody>
        </table>
        <!-- <table class="table_form fr" style="width: 1120px; border-top: 0px"> -->
        <table style="width: 60%; border-top: 0px">
          <thead class="sub_title_txt">
            <tr>
              <td colspan="3">
                <h2 style="width: 200px" class="fl">{{ $_msgContents('UC.WORD.LINE_INFO') }}</h2>
                <DxButton
                  @click="onUpdateDevice(i)"
                  :text="$_msgContents('UC.WORD.APPLY')"
                  :width="80"
                  :height="26"
                  class="dx-widget dx-button dx-button-mode-text dx-button-normal dx-button-has-text btn_XS default filled fr"
                  styling-mode="contained"
                  type="default"
                />
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style="padding: 0">
                <!-- <div class="layout-cut-box"> -->
                <esp-dx-data-grid
                  :data-grid="getDataGrid(i)"
                  :ref="getDataGridRef(i)"
                  :auto-width="true"
                  @row-updating="onRowUpdating($event, i)"
                  @cell-prepared="onCellPrepared"
                  @editor-prepared="onEditorPrepared"
                ></esp-dx-data-grid>
                <!-- </div> -->
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </template>

    <DxPopup
      :show-title="true"
      :title="modal.title"
      :width="modal.width"
      :height="modal.height"
      :drag-enabled="true"
      :resize-enabled="true"
      :show-close-button="true"
      :close-on-outside-click="false"
      :visible="modal.isOpened"
      @hiding="isOpenModal(false)"
    >
      <DxScrollView width="100%" height="100%">
        <ModalExpansion
          :deviceInfo="this.deviceInfo"
          :serverInfo="this.serverInfo"
          :cmList="this.cmList"
          :isOpened="this.modal.isOpened"
          :authority="this.authority"
        ></ModalExpansion>
      </DxScrollView>
    </DxPopup>
    <DxPopup
      :show-title="true"
      :title="pickupModal.title"
      :width="pickupModal.width"
      :height="pickupModal.height"
      :drag-enabled="true"
      :resize-enabled="true"
      :show-close-button="true"
      :close-on-outside-click="false"
      :visible="pickupModal.isOpened"
      @hiding="isOpenPickupPopup(false)"
    >
      <ModalPickup
        :deviceInfo="this.deviceInfo"
        :serverInfo="this.serverInfo"
        :rowInfo="this.rowInfo"
        :cmList="this.cmList"
        :isOpened="this.pickupModal.isOpened"
        :gridNumber="this.gridNumber"
        :authority="this.authority"
        :pickupKeyword="this.pickupKeyword"
        @closePickup="setPickup"
      ></ModalPickup>
    </DxPopup>
    <DxPopup
      :show-title="true"
      :title="sdModal.title"
      :width="sdModal.width"
      :height="sdModal.height"
      :drag-enabled="true"
      :resize-enabled="true"
      :show-close-button="true"
      :close-on-outside-click="false"
      :visible="sdModal.isOpened"
      @hiding="isOpenSdPopup(false)"
    >
      <ModalSd
        :deviceInfo="this.deviceInfo"
        :cmList="this.cmList"
        :sdData="this.sdModal.data"
        :isOpened="this.sdModal.isOpened"
        :idx="this.sdModal.idx"
        :authority="this.authority"
        @closeSd="setLine"
      ></ModalSd>
    </DxPopup>
  </div>
</template>

<script>
  import DxButton from 'devextreme-vue/button';
  import { DxSelectBox } from 'devextreme-vue/select-box';
  import ModalExpansion from '../device/cisco/modal-set-expansion.vue';
  import ModalPickup from '../device/cisco/modal-set-pickup.vue';
  import { DxPopup } from 'devextreme-vue/popup';
  import PlusIcon from '@/assets/images/write.png';
  import DelIcon from '@/assets/images/del.png';
  import { isSuccess } from '@/plugins/common-lib';
  import { DxTextBox } from 'devextreme-vue/text-box';
  import { DxScrollView } from 'devextreme-vue/scroll-view';
  import ModalSd from '@/pages/euc/device/cisco/modal-set-sd.vue';
  import EspDxDataGrid from "@/components/devextreme/esp-dx-data-grid.vue";

  export default {
    components: {
      EspDxDataGrid,
      DxButton,
      DxTextBox,
      DxSelectBox,
      DxPopup,
      ModalExpansion,
      ModalPickup,
      DxScrollView,
      ModalSd,
    },
    props: {},
    watch: {},
    data() {
      return {
        config: {
          pageSetting: {
            //pageData pageSetting 관련
            config: {},
          },
        },
        stylingMode: 'outlined', //outlined, underlined, filled
        devicePoolData: [],
        selectedPool: '',
        authority: 'user',
        deviceCnt: 0,
        phoneButtonData: [],
        selectedBtn: '',
        softKeyData: [],
        selectedKey: '',
        cssData: [],
        myDeviceInfo: [],
        partitionData: [],
        cmList: [],
        gridNumber: '',
        deviceImage: [],
        deviceExpImage: [],
        userDept: '',
        userInfo: {},
        pickupKeyword: '',
        modal: {
          isOpened: false,
          // title: '확장 모듈 설정',
          title: this.$_msgContents('UC.WORD.EXPANSION_MODULE_SET'),
          width: '50%',
          height: '900',
        },
        pickupModal: {
          isOpened: false,
          // title: '당겨받기 설정',
          title: this.$_msgContents('UC.WORD.PICKUPGROUP_SET'),
          width: '60%',
          height: '700',
        },
        sdModal: {
          isOpened: false,
          data: [],
          idx: '',
          title: '단축다이얼 설정',
          width: '30%',
          height: '300',
        },
        pickupGroupList: [],
        updateFlag: false,
        serverInfo: '',
        deviceInfo: {},
        devicelist: [],
        rowInfo: {},
        changedDeviceInfo: [],
        dataGrid: {},
      };
    },
    computed: {
      /** @description: pageData -> pageSetting apiActionNm 가져오기 */
      getApiActionNm() {
        return this.config.pageSetting.config?.apiActionNm || null;
      },
    },
    methods: {
      /**@description index에 따른 그리드 리턴 */
      getDataGrid(index) {
        return this.dataGrid[`dataGrid_${index}`];
      },
      /**@description index에 따른 그리드 ref 리턴 */
      getDataGridRef(index) {
        return `refDataGrid_${index}`;
      },
      /**@description 그리드 dataSource set 함수 */
      setDataSource(index, data) {
        let gridDataSource = [];

        for (let i = 0; i < data.length; i++) {
          if (data[i].addonmodule === 'false') {
            gridDataSource.push(data[i]);
          }
        }
        this.dataGrid[`dataGrid_${index}`].dataSource = gridDataSource;
        this.dataGrid[`dataGrid_${index}`].totalCount = gridDataSource.length;
      },
      /**@description index에 따른 그리드 dataSource 리턴 */
      getDataSource(index) {
        return this.dataGrid[`dataGrid_${index}`].dataSource;
      },
      /**@description deviceList length 따른 그리드 다중 생성 */
      createGrid() {
        for (let i = 0; i < this.deviceCnt; i++) {
          this.$set(this.dataGrid, `dataGrid_${i}`, {
            refName: `refDataGrid_${i}`,
            allowColumnResizing: false, //컬럼 사이즈 허용
            showBorders: true, //border 유무
            showColumnHeaders: true, //컬럼 헤더 유무
            showColumnLines: false, //컬럼 세로선 유무
            showRowLines: true, //컬럼 가로선 유무
            rowAlternationEnabled: false,
            disableTotalCount: true,
            dataSource: [],
            // width: '1120', // 주석처리시 100%
            height: '251', // 주석처리시 100%
            apiActionNm: {
              update: '',
            },
            customEvent: {
              //그리드 컴포넌트의 이벤트를 해당 페이지에서 사용할 수 있도록 처리 [ 사용: true, 미사용: false(생략 가능) ]
              cellPrepared: true,
              saving: true,
              rowUpdating: true,
              rowUpdated: true,
              rowPrepared: true,
              editorPrepared: true,
            },
            showActionButtons: {
              excel: false, // 엑셀 버튼
              customButtons: [],
            },
            isDuplicateConfigKey: false, //설정키 중복 체크
            grouping: {
              contextMenuEnabled: false,
              autoExpandAll: false,
              allowCollapsing: true,
              expandMode: 'rowClick', // rowClick or buttonClick
            },
            groupPanel: {
              visible: false,
            },
            columnChooser: {
              enabled: false, // 컬럼 Chooser 버튼 사용유무
            },
            loadPanel: {
              enabled: false, // 로딩바 표시 유무
            },
            sorting: {
              mode: 'single', // single multiple
            },
            remoteOperations: {
              // 서버사이드 여부
              filtering: false,
              sorting: false,
              grouping: false,
              paging: false,
            },
            paging: {
              enabled: false,
              // pageSize: 10,
              // pageIndex: 0, // 시작페이지
            },
            pager: {
              visible: false, //페이저 표시 여부
              showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
              allowedPageSizes: [],
              displayMode: 'compact', //표시 모드 : ['full', 'compact']
              showInfo: false, //페이지 정보 표시 여부 : full인 경우만 사용 가능
              showNavigationButtons: false, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
            },
            filterRow: {
              visible: false,
            },
            headerFilter: {
              visible: false,
            },
            editing: {
              allowUpdating: true, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
              allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
              allowDeleting: false,
              mode: 'cell', //수정 모드: ['row', 'cell', 'batch']
              startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
              selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
            },
            selecting: {
              mode: 'single', //행 단일/멀티 선택 타입 : ['single', 'multiple']
              selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
              showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
            },
            columns: [
              {
                caption: '유형',
                i18n: 'UC.WORD.TYPE',
                dataField: 'field',
                width: 100,
                height: 40,
                alignment: 'center',
                visible: true,
                allowEditing: false,
                sortOrder: 'none',
                allowSorting: false,
                allowHeaderFiltering: false,
                allowGrouping: false,
                cellTemplate: async (container, options) => {
                  const imgTag = document.createElement('img');
                  const imgData = this.$_getAttachFileURL(null, options.value);
                  imgTag.setAttribute('src', imgData);
                  container.append(imgTag);
                },
              },
              {
                caption: '내선번호',
                i18n: 'UC.WORD.NUMBER',
                dataField: 'number',
                width: 150,
                height: 40,
                alignment: 'center',
                visible: true,
                allowEditing: false,
                allowSorting: false,
                sortOrder: 'none',
                allowHeaderFiltering: false,
                allowGrouping: false,
                cellTemplate: (container, options) => {
                  if (options.data.field == 'LINE') {
                    if (options.value == null || options.value == 'null') {
                      options.value = '';
                    }
                    container.append(options.value);
                  } else {
                    if (options.value == null || options.value == 'null') {
                      options.value = '';
                    }
                    const value = options.value ? options.value + ' ' : options.value;
                    container.append(value);
                    const divTag = document.createElement('div');
                    divTag.classList.add('fr');
                    const imgTag = document.createElement('img');
                    imgTag.setAttribute('src', PlusIcon);
                    imgTag.style.cursor = 'pointer';
                    imgTag.addEventListener('click', async () => {
                      // await this.isOpenModal(true, field, options.data);
                      await this.isOpenSdPopup(true, i);
                    });

                    divTag.append(imgTag);
                    if (value != '') {
                      const del = document.createElement('img');
                      del.setAttribute('src', DelIcon);
                      del.style.cursor = 'pointer';
                      del.addEventListener('click', async () => {
                        await this.setCellDelete(options, i);
                      });
                      divTag.append(del);
                    }
                    container.append(divTag);
                  }
                },
              },
              {
                caption: '표시',
                i18n: 'UC.WORD.LABEL',
                dataField: 'label',
                // width: 100,
                height: 40,
                alignment: 'center', // left center right
                visible: true,
                allowEditing: true,
                allowSorting: false,
                sortOrder: 'none', // acs desc none
                allowHeaderFiltering: false,
                fixed: false, // 컬럼 fix 시 사용
                fixedPosition: 'left', // left or right
              },
              {
                caption: '착신전환',
                i18n: 'UC.WORD.FORWARDNO',
                dataField: 'forwardno',
                // width: 120,
                height: 40,
                alignment: 'center', // left center right
                visible: true,
                allowEditing: true,
                allowUpdating: true,
                allowSorting: false,
                sortOrder: 'none', // acs desc none
                allowHeaderFiltering: false,
                fixed: false, // 컬럼 fix 시 사용
                fixedPosition: 'left', // left or right
                validationRules: [
                  {
                    type: 'custom',
                    validationCallback: function (e) {
                      if (e.value === '' || e.value == undefined) {
                        return true;
                      }
                      return !isNaN(e.value) && e.value !== null && e.value !== '';
                    },
                    message: this.$_msgContents('UC.MESSAGE.NUMBER_ENTER'),
                  },
                ],
              },
              {
                caption: '당겨받기',
                i18n: 'UC.WORD.PICKUPGROUP',
                dataField: 'pickupgroupname',
                // width: 150,
                height: 40,
                alignment: 'center',
                visible: true,
                allowEditing: false,
                allowSorting: false,
                sortOrder: 'none',
                allowHeaderFiltering: false,
                allowGrouping: false,
                cssClass: '',
                cellTemplate: (container, options) => {
                  if (options.value === null || typeof options.value === 'undefined') {
                    options.value = '';
                  }
                  if (options.data.field === 'LINE') {
                    if (options.data.number == null || options.data.number == '') {
                      return;
                    }
                    let description = '';
                    for (let k = 0; k < this.pickupGroupList.length; k++) {
                      if (this.pickupGroupList[k].name === options.value) {
                        description = this.pickupGroupList[k].description;
                      }
                    }
                    const value = description;
                    container.append(value + ' ');
                    const divTag = document.createElement('div');
                    divTag.classList.add('fr');
                    const imgTag = document.createElement('img');
                    imgTag.setAttribute('src', PlusIcon);
                    imgTag.addEventListener('click', async () => {
                      await this.setRowInfo(options.data);
                      await this.isOpenPickupPopup(true, i);
                    });
                    imgTag.style.cursor = 'pointer';
                    divTag.append(imgTag);
                    if (value != '') {
                      const del = document.createElement('img');
                      del.setAttribute('src', DelIcon);
                      del.addEventListener('click', async () => {
                        await this.setCellDelete(options, i);
                      });
                      del.style.cursor = 'pointer';
                      divTag.append(del);
                    }
                    container.append(divTag);
                  }
                },
              },
            ],
          });
        }
      },
      /** @description: 단말장치 데이터 상세 조회 메서드 */
      async selectData() {
        if (this.userInfo == {} || this.userInfo == null) {
          this.$_goMainPage();
          return;
        }
        this.changedDeviceInfo = []; //변경내역 초기화
        const params = { loginId: this.$store.getters.getLoginId, type: 'DEVICE', device: this.userInfo.dn };
        const payload = {
          actionname: 'EUC_DEVICE_CISCO_DETAIL',
          data: params,
          loading: true,
        };

        const res = await this.CALL_EUC_API(payload);
        if (isSuccess(res)) {
          if (res.data.data.length > 0) {
            const deviceInfo = res.data.data[0];
            this.deviceCnt = deviceInfo.LIST.length;
            await this.createGrid();
            await this.setGridDataSource(deviceInfo);
          }
        } else {
          await this.$_Msg(res.data.header.resMsg);
          await this.$_goMainPage();
        }
      },

      /** @description: 단말장치 데이터 상세 조회 메서드 */
      setGridDataSource(json) {
        const list = json.LIST;
        for (let i = 0; i < list.length; i++) {
          const refDeviceData = list[i];
          if (i > -1 && this.myDeviceInfo.length === list.length) {
            Object.assign(this.myDeviceInfo[i], refDeviceData);
          } else {
            this.myDeviceInfo.push(refDeviceData);
          }
          const refGridData = refDeviceData.devicelist;
          // const refGridData = refDeviceData.devicelist.filter(d => d.number != null);
          let img = { index: '', src: '' };
          let imgExp = { index: '', src: '' };
          if (refDeviceData?.model) {
            img.index = i;
            img.src = this.$_getAttachFileURL(null, refDeviceData.model);
            if (i > -1 && this.deviceImage.length === list.length) {
              Object.assign(this.deviceImage[i], img);
            } else {
              this.deviceImage.push(img);
            }
          } else {
            img.index = i;
            img.src = null;
            if (i > -1 && this.deviceImage.length === list.length) {
              Object.assign(this.deviceImage[i], img);
            } else {
              this.deviceImage.push(img);
            }
          }
          if (refDeviceData?.addonmodules) {
            imgExp.index = i;
            imgExp.src = this.$_getAttachFileURL(null, refDeviceData.addonmodules);
            if (i > -1 && this.deviceExpImage.length === list.length) {
              Object.assign(this.deviceExpImage[i], imgExp);
            } else {
              this.deviceExpImage.push(imgExp);
            }
          } else {
            if (i > -1 && this.deviceExpImage.length === list.length) {
              Object.assign(this.deviceExpImage[i], imgExp);
            } else {
              this.deviceExpImage.push(imgExp);
            }
          }
          this.setDataSource(i, refGridData);
          //그리드 개수만큼 초기화
          this.changedDeviceInfo.push([]);
        }
      },
      /**@description 그리드 customEvent @onRowUpdating*/
      onRowUpdating(e, index) {
        // let array = [];
        let column = Object.keys(e.newData)[0];
        let value = e.newData[column];
        let number = e.oldData.number;
        let field = e.oldData.field;
        let newRow = { column: column, value: value, number: number, field: field };
        const existingRow = this.changedDeviceInfo[index].find(row => row.column === column && row.number === number);

        if (existingRow) {
          Object.assign(existingRow, newRow);
        } else {
          this.changedDeviceInfo[index].push(newRow);
        }
      },
      /** @description 로그인 유저의 인사정보 조회  */
      async getUserInfo() {
        this.userInfo = this.$store.getters.getUserInfo;
        if (this.userInfo == null) {
          await this.$_Msg(this.$_msgContents('UC.MESSAGE.NO_PERSON_INFO'));
          await this.$_goMainPage();
          return;
        } else {
          if (this.userInfo.dn == null) {
            await this.$_Msg('로그인한 유저의 내선정보가 없습니다.');
            await this.$_goMainPage();
          } else {
            await this.selectData();
          }
        }
      },
      /**@description  단말기 정보 업데이트
       * @param {String} 그리드 넘버정보
       * */
      async onUpdateDevice(index) {
        const changeArr = [];
        //
        let refs = this.getDataGridRef(index);
        if (this.$refs[refs][0].hasEditData()) {
          await this.$_Msg('수정중인 항목이 있습니다.');
          return false;
        }
        for (let i = 0; i < this.changedDeviceInfo[index].length; i++) {
          let row = {
            TYPE: '',
            DEVICE: '',
            ROUTEPARTITION: '',
          };
          const routeRow = this.getDataSource(index).find(
            row => row.number === this.changedDeviceInfo[index][i].number,
          );

          row.DEVICE = this.changedDeviceInfo[index][i].number;
          if (routeRow.field === 'LINE') {
            row.ROUTEPARTITION = routeRow.routepartitionname;
          } else {
            row.ROUTEPARTITION = '';
          }

          switch (this.changedDeviceInfo[index][i].column) {
            case 'label':
              row.TYPE = this.changedDeviceInfo[index][i].field + 'LABEL';
              row.LABEL = this.changedDeviceInfo[index][i].value;
              break;
            case 'forwardno':
              row.TYPE = 'FORWARDCALL';
              row.FORWARD_NO = this.changedDeviceInfo[index][i].value;
              break;
            case 'pickupgroupname':
              row.TYPE = 'PICKUP';
              row.PICKUPGROUP = this.changedDeviceInfo[index][i].value;
              break;
          }
          changeArr.push(row);
        }
        if (changeArr.length === 0 && this.updateFlag == false) {
          await this.$_Msg(this.$_msgContents('COMMON.MESSAGE.CMN_NO_CHANGED'));
          return;
        } else if (changeArr.length > 0 || this.updateFlag == true) {
          const params = {
            cmNm: this.myDeviceInfo[index].cmname,
            mac: this.myDeviceInfo[index].name,
            description: this.myDeviceInfo[index].description,
            list: JSON.stringify(changeArr),
            loginId: this.$store.getters.getLoginId,
          };
          const payload = {
            actionname: 'EUC_DEVICE_CISCO_UPDATE',
            data: params,
            loading: true,
          };

          const confirm = this.$_msgContents('COMMON.MESSAGE.CMN_CFM_SAVE', { defaultValue: '저장하시겠습니까?' });

          if (!(await this.$_Confirm(confirm))) {
            return;
          }

          const res = await this.CALL_EUC_API(payload);

          if (isSuccess(res)) {
            await this.$_Msg(this.$_msgContents('COMMON.MESSAGE.CMN_SUCCESS', { defaultValue: '정상적으로 처리되었습니다.' }));
            //업데이트 성공후 초기화
            this.changedDeviceInfo = [];
            await this.selectData();
          } else {
            await this.$_Msg(res.data.header.resMsg);
          }
        }
      },
      /**@description : 설명 입력창 onChange이벤트*/
      onChangeInput(event, index) {
        this.updateFlag = true;
        this.myDeviceInfo[index].description = event.value;
      },
      /**@description : 확장모듈 설정 팝업 Open/Close */
      isOpenModal(bool, index) {
        if (typeof index != 'undefined') {
          this.deviceInfo = this.myDeviceInfo[index];
        }
        this.modal.isOpened = bool;
      },
      /**@description : 당겨받기 설정 팝업 Open/Close */
      isOpenPickupPopup(bool, index) {
        if (typeof index != 'undefined') {
          this.gridNumber = index.toString();
          this.deviceInfo = this.myDeviceInfo[index];
          this.pickupKeyword = this.userInfo.deptNm;
        }
        this.pickupModal.isOpened = bool;
      },
      isOpenSdPopup(bool, index) {
        if (typeof index != 'undefined') {
          this.sdModal.data = JSON.parse(
            JSON.stringify(this.getDataSource(index).filter(d => d.field === 'SD' || d.field === 'BLF')),
          );
          this.sdModal.idx = index.toString();
        }
        this.sdModal.isOpened = bool;
      },
      async setLine(sdData, idx) {
        this.isOpenSdPopup(false, idx);
        const ref = 'refDataGrid_' + idx;
        const gridData = this.getDataSource(idx);

        for (let i = 0; i < gridData.length; i++) {
          for (let j = 0; j < sdData.length; j++) {
            if (
              gridData[i].index === sdData[j].index &&
              gridData[i].seq === sdData[j].seq &&
              gridData[i].field === sdData[j].field
            ) {
              this.$refs[ref][0].getGridInstance.cellValue(i, 1, sdData[j].number);
              this.$refs[ref][0].getGridInstance.cellValue(i, 2, sdData[j].label);
              this.$refs[ref][0].getGridInstance.cellValue(i, 3, '');
            }
          }
        }
        await this.$refs[ref][0].getGridInstance.saveEditData();
        await this.onCreateDevice(idx);
      },
      /**@description : 내선 설정 함수 */
      async onCreateDevice(idx) {
        const dataSource = this.getDataSource(idx);
        const mergeArray = [];
        for (let i = 0; i < dataSource.length; i++) {
          if (dataSource[i].number != null && dataSource[i].number != '') {
            let data = {};
            data.INDEX = dataSource[i].seq;
            data.FIELD = dataSource[i].field;
            data.PATTERN = dataSource[i].number == null ? '' : dataSource[i].number;
            data.LABEL = dataSource[i].label == null ? '' : dataSource[i].label;
            if (dataSource[i].field == 'LINE') {
              data.ROUTEPARTITION =
                dataSource[i].routepartitionname == null ? 'None' : dataSource[i].routepartitionname;
              data.CSS = dataSource[i].callingsearchspacename == null ? 'None' : dataSource[i].callingsearchspacename;
            }
            mergeArray.push(data);
          }
        }

        let params = {
          mac: this.myDeviceInfo[idx].name,
          cmNm: this.myDeviceInfo[idx].cmname,
          loginId: this.$store.getters.getLoginId,
          list: JSON.stringify(mergeArray),
        };
        const payload = {
          actionname: 'EUC_DEVICE_CISCO_MERGE',
          data: params,
          loading: true,
        };
        const res = await this.CALL_EUC_API(payload);
        if (isSuccess(res)) {
          await this.$_Msg(this.$_msgContents('COMMON.MESSAGE.CMN_SUCCESS', { defaultValue: '정상적으로 처리되었습니다.' }));
        } else {
          await this.$_Msg(res.data.header.resMsg);
        }
        await this.selectData();
      },

      /**@description 할당된 내선 삭제하는 메서드 */
      async onDeleteDevice(idx) {
        const dataSource = this.getDataSource(idx);
        const mergeArray = [];
        for (let i = 0; i < dataSource.length; i++) {
          if (dataSource[i].number != null && dataSource[i].number != '') {
            let data = {};
            data.INDEX = dataSource[i].seq;
            data.FIELD = dataSource[i].field;
            data.PATTERN = dataSource[i].number == null ? '' : dataSource[i].number;
            data.LABEL = dataSource[i].label == null ? '' : dataSource[i].label;
            if (dataSource[i].field == 'LINE') {
              data.ROUTEPARTITION =
                dataSource[i].routepartitionname == null ? 'None' : dataSource[i].routepartitionname;
              data.CSS = dataSource[i].callingsearchspacename == null ? 'None' : dataSource[i].callingsearchspacename;
            }
            mergeArray.push(data);
          }
        }

        let params = {
          mac: this.myDeviceInfo[idx].name,
          cmNm: this.myDeviceInfo[idx].cmname,
          loginId: this.$store.getters.getLoginId,
          list: JSON.stringify(mergeArray),
        };
        const payload = {
          actionname: 'EUC_DEVICE_CISCO_MERGE',
          data: params,
          loading: true,
        };

        const res = await this.CALL_EUC_API(payload);
        if (isSuccess(res)) {
          await this.$_Msg(this.$_msgContents('COMMON.MESSAGE.CMN_SUCCESS', { defaultValue: '정상적으로 처리되었습니다.' }));
        } else {
          await this.$_Msg(res.data.header.resMsg);
        }
        await this.selectData();
      },
      /**@description : 당겨받기 데이터 저장하는 메서드 */
      setRowInfo(rowData) {
        this.rowInfo = rowData;
      },
      /**@description 교환기 목록 가져오는 메서드 */
      async selectCmList(sort = '+cmOrd') {
        const params = { sort: sort, viewFl: 'Y' };
        const payload = {
          actionname: 'EUC_CM_LIST_ALL',
          data: params,
          loading: false,
        };

        const res = await this.CALL_EUC_API(payload);
        if (isSuccess(res)) {
          await this.setCmName(res);
        }
      },
      /**@description 당겨받기그룹 목록 가져오는 메서드 */
      async selectPickupGroupList(sort = 'regDt,id') {
        let params = { sort: sort, viewFl: 'Y' };

        const payload = {
          actionname: 'EUC_PICKUPGROUP_LIST_ALL',
          data: params,
          loading: false,
        };

        const res = await this.CALL_EUC_API(payload);
        if (isSuccess(res)) {
          if (res.data.data.length > 0) {
            this.pickupGroupList = res.data.data;
          }
        }
      },

      setCmName(res) {
        const length = res.data.data?.length;
        if (length > 0) {
          this.cmList = res.data.data;
          for (let i = 0; i < this.cmList.length; i++) {
            delete this.cmList[i].id;
            delete this.cmList[i].cmIp;
            delete this.cmList[i].passwd;
            delete this.cmList[i].pinPasswd;
            delete this.cmList[i].regDt;
            delete this.cmList[i].siteId;
            delete this.cmList[i].tenantId;
            delete this.cmList[i].userId;
            delete this.cmList[i].vender;
            delete this.cmList[i].viewCd;
          }
        }
      },
      /**@description 교환기 영문명 -> 한글명 교체 */
      getCmName(cm) {
        let cmNm = '';
        for (let i = 0; i < this.cmList.length; i++) {
          if (this.cmList[i].cmNm === cm) {
            cmNm = this.cmList[i].description;
          }
        }
        if (cmNm !== '' && cmNm !== null) {
          return cmNm;
        } else {
          return '';
        }
      },
      /** @description : 날짜 형식 변환 메서드 */
      formatDt(date) {
        return this.$_commonlib.formatDate(date, 'YYYYMMDDHHmmssSSS', 'YYYY.MM.DD');
      },
      /** @description : 그리드 셀 관련 준비 커스텀 이벤트 */
      onCellPrepared(e) {
        if (e.rowType === 'data') {
          if (e.data.field !== 'LINE') {
            if (e.columnIndex > 2) {
              e.cellElement.classList.add('grid-edit-false');
            }
          } else {
            if (e.columnIndex == 1) {
              e.cellElement.classList.add('grid-edit-false');
            }
          }
        }
      },
      /** @description 그리드 셀 편집기가 생성된 후 실행 */
      onEditorPrepared(e) {
        if (e.dataField === 'label' || e.dataField === 'forwardno') {
          if ((e.row.cells[0].value === 'SD' || e.row.cells[0].value === 'BLF') && e.dataField == 'forwardno') {
            e.component.cancelEditData();
          }
          // if (e.row.cells[0].value !== 'LINE') {
          //   e.component.cancelEditData();
          // }
          // if (e.row.cells[0].value == 'LINE' && (e.row.cells[1].value == '' || e.row.cells[1].value == null)) {
          //   e.component.cancelEditData();
          // }
        }
        if (e.row.rowType === 'data' && (e.row.data.number === null || e.row.data.number === '')) {
          e.component.cancelEditData();
        }
      },
      /** @description 당겨받기 팝업창 콜백함수 */
      async setPickup(row, pickupName, index) {
        this.isOpenPickupPopup(false, index);
        const ref = 'refDataGrid_' + index;
        const gridData = this.getDataSource(index);
        for (let i = 0; i < gridData.length; i++) {
          if (gridData[i].index === row.index && gridData[i].seq === row.seq && gridData[i].field === row.field) {
            this.$refs[ref][0].getGridInstance.cellValue(i, 4, pickupName);
            this.$refs[ref][0].getGridInstance.saveEditData();
          }
        }
      },
      /**@description 그리드 내부 X버튼 클릭 이벤트. 셀 내부 데이터 삭제 */
      setCellDelete(row, index) {
        const ref = 'refDataGrid_' + index;
        this.$refs[ref][0].getGridInstance.cellValue(row.rowIndex, row.columnIndex, '');
        this.$refs[ref][0].getGridInstance.saveEditData();

        if (row.columnIndex === 1 && row.rowType === 'data') {
          this.onDeleteDevice(index);
        }
      },
      /**@description : 라이프사이클 created시 호출되는 메서드 */
      async createdData() {
        this.$_setPageSettingConfig();
        await this.getUserInfo();
      },
      /**@description : 라이프사이클 mounted시 호출되는 메서드 */
      async mountedData() {
        // await this.getUserInfo();
        await this.selectCmList();
        await this.selectPickupGroupList();
        // await this.selectData();
      },
      /**@description : 라이프사이클 destroyed시 호출되는 메서드 */
      destroyedData() {
        this.$store.commit('setDetailParams', null);
      },
    },
    async created() {
      await this.createdData();
    },
    async mounted() {
      await this.mountedData();
    },
    destroyed() {
      this.destroyedData();
    },
  };
</script>

<style scoped>
  .device-img > img {
    align-self: center;
  }

  .fl {
    border-right: 0 !important;
  }

  tbody {
    display: table-row-group;
    vertical-align: top;
  }

  .dx-datagrid-headers {
    border-top: 0px;
  }

  .table_form tbody td {
    border: 1px solid #ddd;
    height: 50px;
    padding: 8px 20px;
  }
  .table_form tbody th {
    border: 1px solid #ddd;
    height: 50px;
    padding: 8px 20px;
  }
  .dx-datagrid .dx-row-lines > td {
    border-bottom: 1px solid #efefef;
  }
  .grid-edit-false {
    background-color: #f9f9f9;
  }
</style>
<style>
  .grid-edit-false {
    background-color: #f9f9f9;
  }
</style>
